import { images } from "./notebook-app/images";

export const articles = [
  
  // {
  //   title: "",
  //   desc:
  //     "",
  //   tags: ["AWS", "EC2", "Cloud Computing"],
  //   github_url: "https://shubhamrasal.tech",
  //   link:
  //     "",
  //   external: true,
  //   live: "https://shubhamrasal.tech",
  //   published: "12 september 2020",
  //   readTime: " min read",
  //   views: ""
  // },
  {
    title: "Create Simple Chat App Using UDP Protocol In Python",
    // desc:
      // "Different portfolio templates to show your skills, experience, articles etc",
    tags: ["python", "networking", "linux"],
    github_url: "https://github.com/MA-Ahmad/portfolio-template1",
    link:
      "https://developer-shubham-rasal.medium.com/create-simple-chat-app-using-udp-protocol-in-python-4539cdbb1ae1",
    external: true,
    // isNew: true,
    live: "https://shubhamrasal.tech",
    published: "18 April 2021",
    readTime: "4 min read",
    views: "1.4k"
  },
  {
    title: "Launch and Configure docker container using ansible-playbook",
    desc:
      "Even this is a very rare use case where we need to configure the container using ansible. Enabling ssh inside the container is not a good practice, but in some cases,...",
    tags: ["AWS", "EC2", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "21 January 2021",
    readTime: "5 min read",
    views: "201"
  },
  {
    title: "Create Simple Chat App Using UDP Protocol In Python",
    desc:
      "we are going to create a chat server based on UDP protocol in Python",
    tags: ["Python", "Networking", "Programming", "linux"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/create-simple-chat-app-using-udp-protocol-in-python-4539cdbb1ae1",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "18 April 2021",
    readTime: "4 min read",
    views: "2.4k"
  },

  {
    title: "How to load variable dynamically according to os in ansible?",
    desc:
      "",
    tags: ["Ansible", "Load balancer", "Configuration Managment"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/how-to-load-variable-dynamically-according-to-os-in-ansible-1d6a01425d3b",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "27 March 2021",
    readTime: "4 min read",
    views: "726"
  },
  {
    title: "Launch and Configure docker container using ansible-playbook",
    desc:
      "Launch and Deploy python flask app on docker container using ansible",
    tags: ["Ansible", "Configuration Managment", "Docker"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://faun.pub/launch-and-configure-docker-container-using-ansible-playbook-95607550623f",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "21 January 2021",
    readTime: "6 min read",
    views: "6k"
  },
  {
    title: "Create a role for setting up a load balancer and web server dynamically",
    desc:
      "Ansible role for setting up load balancer using HAProxy and webserver using Apache software.",
    tags: ["Ansible", "Configuration Managment", "Apache", "Linux"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/create-a-role-for-setting-up-a-load-balancer-and-web-server-dynamically-8f4e717eee30",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "11 January 2021",
    readTime: "5 min read",
    views: "1k"
  },

  {
    title: "Write Ansible role to configure apache webserver",
    desc:
      "Write Ansible role to configure apache webserver on Redhat and Ubuntu",
    tags: ["Ansible", "Configuration Managment", "Apache", "Linux"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://faun.pub/ansible-write-ansible-role-to-configure-apache-webserver-9c08aaf66528",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "10 January 2021",
    readTime: "6 min read",
    views: "2k"
  },
  
  {
    title: "Why you should use Kubernetes?",
    desc:
      "Introduction to Kubernetes. Why we need Kubernetes? Use cases of Kubernetes.",
    tags: ["Cloud Native", "Kubernetes", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://faun.pub/why-you-should-use-kubernetes-bf395bef52de",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "1 January 2021",
    readTime: "7 min read",
    views: "234"
  },
  {
    title: "How Ansible Tower helps to achieve automation?",
    desc:
      "https://developer-shubham-rasal.medium.com/how-ansible-tower-helps-to-achieve-automation-b354bc8825ab",
    tags: ["Ansible", "Automation", "Ansible-Tower"],
    github_url: "https://shubhamrasal.tech",
    link:
      "",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "29 December 2020",
    readTime: "4 min read",
    views: "560"
  },

  {
    title: "How to configure Load Balancer and webserver on AWS using Ansible Playbook?",
    desc:
      "Configure Haproxy dynamically when a new webserver gets added using ansible",
    tags: ["Ansible","Apache","HAProxy", "Load balancer", "Automation", "Configuration managment"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://faun.pub/how-to-configure-load-balancer-and-webserver-on-aws-using-ansible-playbook-60c22c0355ed",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "25 December 2020",
    readTime: "7 min read",
    views: "228"
  },



  {
    title: "How to configure apache webserver using ansible?",
    desc:
      "",
    tags: ["Ansible","Apache", "Automation", "Configuration managment"],
    github_url: "https://shubhamrasal.tech",
    link:
      "",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "18 December 2020",
    readTime: "4 min read",
    views: "355"
  },

  {
    title: "How to configure Hadoop Cluster using Ansible?",
    desc:
      "Configure the Hadoop name node and data node using ansible",
    tags: ["Ansible", "Hadoop", "BigData" , "Automation", "Configuration managment"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/how-to-configure-hadoop-cluster-using-ansible-58d942c59ac0",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "17 December 2020",
    readTime: "6 min read",
    views: "345"
  },

  {
    title: "Launching Docker Container Using Ansible",
    desc:
      "Launch Apache webserver docker container using ansible",
    tags: ["Docker", "Ansible", "Automation", "Configuration managment"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/launching-docker-container-using-ansible-372bbdca9165",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "14 December 2020",
    readTime: "11 min read",
    views: "253"
  },

  {
    title: "Create High Availability Architecture with AWS CLI",
    desc:
      "Creating cloud architecture for the web app with low latency for resources",
    tags: ["AWS", "EC2", "S3", "Cloudfront", "aws-cli"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/create-high-availability-architecture-with-aws-cli-shubham-rasal-7236c75417ad",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "11 December 2020",
    readTime: "6 min read",
    views: "198"
  },

  {
    title: "What is Ansible? Ansible for DevOps",
    desc:
      "How we can use Ansible to automate IT and DevOps?",
    tags: ["DevOps", "Ansible", "IT", "Automation"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/what-is-ansible-acb573304acd",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "1 December 2020",
    readTime: "4 min read",
    views: "694"
  },
  {
    title: "Deploying Angular App to AWS S3 with CloudFront using AWS CLI",
    desc:
      "Deploy angular website on aws using aws CLI",
    tags: ["AWS", "aws-cli", "S3", "Cloudfront", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/deploying-angular-app-to-aws-s3-with-cloudfront-using-aws-cli-ace33350a950",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "8 October 2020",
    readTime: "6 min read",
    views: "2k"
  },
  {
    title: "What is AWS CLI ? How to use AWS CLI ?",
    desc:
      "Launch EC2 instance, create EBS and attach EBS volume to EC2 instance using aws CLI",
    tags: ["AWS", "aws-cli", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/what-is-aws-cli-how-to-use-aws-cli-6f1bdedabd2b",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "12 september 2020",
    readTime: "5 min read",
    views: "317"
  },
  {
    title: "Building Scalable Applications and Microservices on AWS",
    desc:
      "How is AWS helping to build microservices architecture to solve the new age problem?",
    tags: ["AWS", "Microservices", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/building-scalable-applications-and-microservices-on-aws-b57672d24378",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "21 september 2020",
    readTime: "6 min read",
    views: "400"
  },

  {
    title: "What is Big Data? How big companies manage Big Data?",
    desc:
      "How big MNC’s like Google, Facebook, Instagram, etc stores, manages, and manipulate Thousands of Terabytes of data with High Speed and High Efficiency",
    tags: ["Use case", "Big Data", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/what-is-big-data-how-big-companies-manage-big-data-21124d639d50",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "17 september 2020",
    readTime: "3 min read",
    views: "100"
  },
  {
    title: "Deploy Blog Site on AWS-EKS using EFS provisioner",
    desc:
      "aunch a blog site in WordPress and MYSQL in Amazon Elastic Kubernetes Service (EKS) using the storage provisioner Elastic File System (EFS)",
    tags: ["AWS", "EKS", "Kubernetes"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://www.linkedin.com/pulse/deploy-blog-site-aws-eks-using-efs-provisioner-shubham-rasal/",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "12 september 2020",
    readTime: " min read",
    views: "53"
  },
  {
    title: "Deploy the WordPress application on Kubernetes and AWS RDS using terraform",
    desc:
      "Infrastructure as code using Terraform, which automatically deploys the WordPress application using AWS RDS service",
    tags: ["AWS", "Kubernetes", "Terraform"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/deploy-the-wordpress-application-on-kubernetes-and-aws-rds-using-terraform-e8501e1e9651",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "1 september 2020",
    readTime: "4 min read",
    views: "250"
  },

  {
    title: "AWS Networking using Terraform (use case 2)",
    desc:
      "Creating VPC, subnets, Internet Gateway, NAT Gateway, Route Table, Bastion host, Servers using Terraform Code",
    tags:["AWS", "Terraform", "Networking", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/aws-networking-using-terraform-cbbf28dcb124",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "26 July 2020",
    readTime: " min read",
    views: "300"
  },
  {
    title: "AWS Networking using Terraform(use case 1)",
    desc:
      "Creating VPC, subnets, Internet Gateway, Route Table, EC2 using Terraform",
    tags: ["AWS", "Terraform", "Networking", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/aws-networking-using-terraform-8a72e7f2aa3d",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "26 July 2020",
    readTime: "6 min read",
    views: "350"
  },
  {
    title: "How to Create Infrastructure on AWS for launching application?",
    desc:
      "Creating Infrastructure As Code for AWS EC2+EFS+S3+CloudFront",
    tags: ["AWS", "Terraform","web deployment", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/how-to-create-infrastructure-on-aws-for-launching-application-103d673f4a87",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "18 July 2020",
    readTime: "6 min read",
    views: "1.1k"
  },
  {
    title: "Launching Web Application with AWS Services using Terraform and Git",
    desc:
      "Write Terraform code to launch Web application on AWS EC2, EBS which serves static/media files using S3 and cloudfront.",
    tags: ["Terraform", "AWS", "Git", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/launching-web-application-with-aws-using-terraform-and-git-fd7484922e4d",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "13 June 2020",
    readTime: "6 min read",
    views: "1k"
  },
  {
    title: "Launching AWS EC2 Webserver Instance in Terraform",
    desc:
      "The plan is simple we want the webserver with our custom security group which will have our Github repository code.",
    tags: ["Terraform", "AWS", "EC2", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/launching-aws-ec2-webserver-instance-in-terraform-8e219fe7be66",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "12 June 2020",
    readTime: " min read",
    views: "300"
  },

  {
    title: "Create AWS EC2 Instance using Terraform",
    desc:
      "Have you tried to write code to launch cloud infra? Learn about what is infrastrcture as Code and Launch your first virtual machine using IaC.",
    tags: ["Terraform", "AWS", "EC2", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/create-aws-ec2-instance-using-terraform-3a3a2d273048",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "12 June 2020",
    readTime: "3 min read",
    views: "220"
  },

  {
    title: "How To Create And Launch Your First EC2 Instance",
    desc:
      "What is EC2? Learn how to launch your first virtual machine on AWS Cloud",
    tags: ["AWS", "EC2", "Cloud Computing"],
    github_url: "https://shubhamrasal.tech",
    link:
      "https://developer-shubham-rasal.medium.com/how-to-create-and-launchyour-first-ec2-instance-d862e0f47306",
    external: true,
    live: "https://shubhamrasal.tech",
    published: "1 June 2020",
    readTime: "3 min read",
    views: "200"
  },
];
