import Mindbowser from "assets/images/logos/mindbowser.jpg";
import LinuxWorld from "assets/images/logos/linuxworld.jpeg";
import Indira from "assets/images/logos/indira_college.png";
import ModernCollege from "assets/images/logos/moden_college.jpeg";
import Arth from "assets/images/logos/arth.png";
import VDS from "assets/images/logos/vds.jpeg";
import Fastlane from "assets/images/skills/fastlane.png";
import vscode from "assets/images/skills/vscode.png";
import Jenkins from "assets/images/skills/jenkins.png";
import bitbucket from "assets/images/skills/bitbucket.svg";
import Github from "assets/images/skills/github.png";
import kube from "assets/images/skills/kube.png"
import javascript from "assets/images/skills/javascript.png";
import Docker from "assets/images/skills/docker.png";
import Terraform from "assets/images/skills/terraform.png"
import html5 from "assets/images/skills/html-5.png";
import css3 from "assets/images/skills/css3.png";
import python from "assets/images/skills/python.png";
import Azure from "assets/images/skills/azure.png";
import postgres from "assets/images/skills/postgresql.png";
import Ansible from "assets/images/skills/ansible.svg";
import mysql from "assets/images/skills/mysql.png";
import heroku from "assets/images/skills/heroku.png";
import docean from "assets/images/skills/digital-ocean.png";
import aws from "assets/images/skills/aws.png";
import nginx from "assets/images/skills/nginx.png";
import redhat from "assets/images/skills/redhat.png"
import { FaDocker } from "react-icons/fa";

export const companies = [
  {
    title: "Mindbowser Inc",
    alt: "Mindbowser image",
    role: "DevOps Engineer",
    skills: ["Jenkins", "Fastlane", "Ansible", "AWS", "GCP", "Azure", "Terraform", "Gradle", "Maven", "Docker"],
    period: "Aug 20 - Present",
    logo: Mindbowser
  },
  {
    title: "LinuxWorld Informatics",
    alt: "LinuxWorld Informatics image",
    role: "Technical Volunteer",
    skills: ["Ansible", "Linux", "Ansible-Roles", "Automation", "Troubleshooting"],
    period: "Nov 2020 - Present",
    logo: LinuxWorld
  }
];

export const institutes = [
  {
    short_title: "Right Arth",
    title: "Arth- The School of Technologies [ rightarth.com ]",
    alt: "https://rightarth.com/",
    role: "Arth Learner",
    skills: ["DevOps", "Cloud Computing","Machine Learning", "BigData", "Programming","Networking", "DSA(algo)"],
    period: "2020 - 2021",
    startingYear: "2020",
    logo: Arth
  },
  {
    short_title: "Indira College of Commerce and Science, Pune",
    title: "Indira College of Commerce and Science, Pune",
    alt: "Indira College Image",
    role: "Masters's Degree in Computer Science",
    skills: ["Project Management", "Web Development", "Databases","Operating System", "Data Structures", "Algorithm"],
    period: "2019 - 2021",
    startingYear: "2019",
    logo: Indira,
    awards: [
      {
        title: ` 
        Winner of Programming competition`,
        description:
          "Won speed programming battle which held at Indira College of Commerce and Science.",
          date: "1 Jan, 2020"
      },
      // {
      //   title: "Published Research papaer",
      //   description:
      //     "Published reaserch paper on subject 'Machine learning model comparision'",
      //   date: "May 1, 2020"
      // }
    ]
  },
  {
    short_title: "Modern College",
    title: "Modern College of Arts, Science and Commerce,Pune",
    alt: "Modern college image",
    role: "Bachelor's Degree in Computer Science ",
    skills: ["Operating System", "Programming","Software Lifecycle", "Networking", "Statistics","Maths", "Electronics"],
    period: "2016 - 2019",
    startingYear: "2016",
    logo: ModernCollege ,
    // awards: [
    //   {
      
    //     title: `Rank Holder`,
    //     description:
    //       "Won speed programming battle which held at Indira College of Commerce and Science.",
    //       date: "1 Jan, 2020"
    //    }
    //   ]
  },
  {
    short_title: "Vidyadham Prashala",
    title: "Vidyadham Prashala,Shirur(Pune)",
    alt: "Modern college image",
    role: "Secondary School",
    skills: [],
    period: "2008 - 2016",
    startingYear: "2016",
    logo: VDS
  }
];

export const skills = [
  // {
  //   name: "React",
  //   description: "Web development",
  //   link: "https://reactjs.org/",
  //   type: "development",
  //   image: react
  // },
  {
    name: "AWS",
    description: "Devops",
    link: "https://aws.amazon.com/",
    type: "devops",
    image: aws
  },
  {
    name: "Jenkins",
    description: "Open Source CI/CD Solution",
    link: "https://www.jenkins.io/",
    type: "devops",
    image: Jenkins
  },
  {
    name: "Python",
    description: "Web development, Scripting",
    link: "https://www.python.org/",
    type: "development",
    image: python
  },
  {
    name: "Docker",
    description: "Containerized Applications",
    link: "https://docker.com/",
    type: "devops",
    image: Docker
  },
  {
    name: "Ansible",
    description: "Configuration Managment",
    link: "https://www.ansible.com/",
    type: "devops",
    image: Ansible
  },
  {
    name: "Kubernetes",
    description: "Container Orchestration",
    link: "https://kubernetes.io/",
    type: "devops",
    image: kube
  },
  {
    name: "Javascript",
    description: "Web development",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    type: "development",
    image: javascript
  },
  // {
  //   name: "Typescript",
  //   description: "Web development",
  //   link: "https://www.typescriptlang.org/",
  //   type: "development",
  //   image: typescript
  // },
  {
    name: "Html5",
    description: "Web layouts",
    link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    type: "development",
    image: html5
  },
  {
    name: "Css3",
    description: "Web design",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    type: "design",
    image: css3
  },
  // {
  //   name: "Bootstrap",
  //   description: "Web design",
  //   link: "https://getbootstrap.com/",
  //   type: "design",
  //   image: bootstrap
  // },
  {
    name: "Postgres",
    description: "Database",
    link: "https://www.postgresql.org/",
    type: "database",
    image: postgres
  },
  {
    name: "Mysql",
    description: "Database",
    link: "https://www.mysql.com/",
    type: "database",
    image: mysql
  },
  // {
  //   name: "Redis",
  //   description: "Database",
  //   link: "https://redis.io/",
  //   type: "database",
  //   image: redis
  // },
  // {
  //   name: "Heroku",
  //   description: "Devops",
  //   link: "https://www.heroku.com/",
  //   type: "devops",
  //   image: heroku
  // },

  {
    name: "Digital Ocean",
    description: "Devops",
    link: "https://www.digitalocean.com/",
    type: "devops",
    image: docean
  },
  {
    name: "Nginx",
    description: "Web server",
    link: "https://www.nginx.com/",
    type: "devops",
    image: nginx
  },
  {
    name: "Visual Studio Code",
    description: "Code editor of choice",
    link: "https://code.visualstudio.com/",
    type: "development",
    image: vscode
  },

  {
    name: "Fastlane",
    description: "Automation for Mobile builds",
    link: "https://fastlane.tools/",
    type: "devops",
    image: Fastlane
  },
  {
    name: "Bitbucket",
    description: "Source Control Managment",
    link: "https://bitbucket.org/",
    type: "devlopment",
    image: bitbucket
  },
  {
    name: "GitHub",
    description: "Source Control Managment",
    link: "https://github.com/",
    type: "devlopment",
    image: Github
  },

  {
    name: "Azure",
    description: "Cloud Computing",
    link: "https://azure.microsoft.com/",
    type: "devops",
    image: Azure
  },
  {
    name: "Terraform",
    description: "Infrastrcutre as a Code tool",
    link: "https://www.terraform.io/",
    type: "devops",
    image: Terraform
  },
  {
    name: "Redhat Linux",
    description: "Linux Operating System",
    link: "https://redhat.com",
    type: "devops",
    image: redhat
  },


];
