import android from "assets/images/projects/android.png";
import ios from "assets/images/projects/iOS.png";
import reactnative from "assets/images/projects/reactnative.png";
import java from "assets/images/projects/java.png";
import angular from "assets/images/projects/angular.png";

export const projectsList = [
  {
    id: 1,
    title: "Android CI/CD",
    logo: android,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "<Enter Android Blog and Repo URL>",
    desc: `Implement multi environment Jenkins CI/CD pipeline which generates android build and share it to testers from firebase app distribution for android project.`,
    technologies: ["gradle", "jenkins", "firebase", "fastlane", "pipeline"]
  },
  {
    id: 2,
    title: "iOS CI/CD",
    link: "<Enter iOS Blog and Repo URL>",
    logo: ios,
    blurHash: "L0Aer?tjH[tPyAayj[j[00ay%xkB",
    desc: `Implement multi environment Jenkins CI/CD pipeline which generates iOS build and share it to testers from testflight for iOS project.`,
    technologies: ["jenkins", "testflight", "fastlane", "pipeline", "iOS"]
  },
  {
    id: 3,
    title: "React Native CI/CD",
    logo: reactnative,
    blurHash: "L2M5%%0000EN00I:-oIp00kC?^sl",
    link: "<Enter react native Blog and Repo URL>",
    desc: `Implement multi environment Jenkins CI/CD pipeline which generates android and iOS
           build and share it to testers from firebase app distribution and testflight for react native project.`,
    technologies: ["gradle", "ios" , "android", "jenkins", "fastlane"]
  },
  {
    id: 4,
    title: "Java CI/CD",
    logo: java,
    blurHash: "L1O^uE0679I00AGT?.H{00y7p2tK",
    link: "<Enter react native Blog and Repo URL>",
    desc: `Automate java spring boot application using ansible playbook and integrate it with jenkins pipeline for deploying application on multiple virtual machines for development, staging and production environment.`,
    technologies: ["java", "jenkins", "ansible", "cicd", "maven", "pipeline", "aws"],
  },
  {
    id: 5,
    title: "Angular CI/CD",
    logo: angular,
    blurHash: "L09sWSxdH;s?%6axWAfk00V?%jax",
    link: "<Enter react native Blog and Repo URL>",
    desc: `Create multi environment Jenkins CI/CD pipeline to deploy angular application using AWS S3 and cloudfront platforms. Write Terraform modules to create aws infrastructure. `,
    technologies: ["angular", "aws", "s3", "cloudfront", "ssl", "jenkins", "pipeline"]
  }
];
